import { Box, IconButton } from "@mui/material";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { CSSProperties, useCallback } from "react";
import { useNotifications } from "@toolpad/core";
import { useDropzone } from "react-dropzone";
import md5 from "md5";
import { useAuth } from "../../Context/AuthContext";
import { useNavigate } from "react-router";

const dropPropsStyle : CSSProperties = {
  display: "flex",
  flexDirection: "row",
  minWidth: 200,
  height: 200,
  borderStyle: "dashed",
  borderColor: "GrayText",
  borderRadius: 1,
  justifyContent: "center",
  alignItems: "center",
}

const picPropsStyle : CSSProperties = {
  display: "flex",
  flexDirection: "column",
  height: 1,
  justifyContent: "center",
}

const closePropsStyle : CSSProperties = {
  position: "absolute",
  top: -20,
  right: -20  
}

const drapIconPropsStyle : CSSProperties = {
  color: "GrayText",
  width: 60,
  height: 60
}

const imgPropsStyle : CSSProperties = {
  width: 198,
  maxHeight: "100%",
  objectFit: "cover",
}

type DropPropsType = {
  reslink: string,
  onComplete: (link:string) => void
}


export default function DropImageComponent(props: DropPropsType) {

  const { reslink, onComplete } = props

  const { token } = useAuth()

  const notification = useNotifications()
  const navigate = useNavigate()

  function clearPic() {
    onComplete("");
  }

  function upload(upfile: File) {
    let base64pic = "";
    let fileReader = new FileReader();

    fileReader.onload = async (e) => {
      base64pic = e.target?.result as string;
      const hash = md5(base64pic);
      const formData = new FormData();
      formData.append("file", upfile);
      formData.append("name", hash);
      try {
        const options = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: formData,
        };
        const res = await fetch("/api/upload_media", options);
        if (res.status === 200) {
          const data = await res.json();
          onComplete(data.link);
        }
        if (res.status === 401 || res.status === 403) {
          navigate("/login");
        }
        if (res.status === 400) {
          const detail = await res.json();
          notification.show(detail.detail.message, {
            severity: "error",
            autoHideDuration: 3000,
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    fileReader.readAsDataURL(upfile);
  }

  const onDrop = useCallback((acceptedFiles: any, fileRejections: any) => {
    if (acceptedFiles.length > 0 && fileRejections.length <= 0) {
      upload(acceptedFiles[0]);
    } else {
      notification.show("选择的图片资源只能是bmp,png或jpg", {
        severity: "info",
        autoHideDuration: 3000,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png", ".PNG"],
      "image/bmp": [".bmp", ".BMP"],
      "image/jpeg": [".jpg", ".JPG"],
    },
    noClick: reslink ? true : false,
  });

  return (
    <Box sx={{ position: "relative" }} {...getRootProps()}>
      <input {...getInputProps()} />
      <Box sx={dropPropsStyle}>
        {!!!reslink ? (
          !isDragActive ? (
            <AddPhotoAlternateOutlinedIcon sx={drapIconPropsStyle} />
          ) : (
            <ArrowDropDownCircleOutlinedIcon sx={drapIconPropsStyle} />
          )
        ) : (
          <Box sx={picPropsStyle}>
            <img src={"/upload/media/" + reslink} style={imgPropsStyle} alt="pic" />
          </Box>
        )}
      </Box>
      {!!reslink && (
        <Box sx={closePropsStyle}>
          <IconButton onClick={clearPic}>
            <CancelIcon sx={{ color: "red", width: 24, height: 24 }} />
          </IconButton>
        </Box>
      )}
    </Box>    
  )
}