import { DashboardLayout } from "@toolpad/core";
import React from "react";
import { Outlet } from "react-router";

export function Layout() {
  return (
    <DashboardLayout disableCollapsibleSidebar sx={{ position: "relative" }} >
      <Outlet />
    </DashboardLayout>
  );
}
