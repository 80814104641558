import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Typography } from "@mui/material";
import { DialogProps } from "@toolpad/core/useDialogs";
import { useState } from "react";

type payloadType = {
  text:string,
  important?:string,
  highlight?:string,
  confirmText:string,
  confirmCheck?: boolean
}

export function ConfirmProceed({ open, onClose, payload }:DialogProps<payloadType, string|null>) {

  // const content:payloadType = JSON.parse(payload)

  const [ enablePrimary, setEnablePrimary ] = useState<boolean>(false)

  return (
    <Dialog fullWidth open={open} onClose={() => onClose(null)}>
      <DialogTitle>
        确认需要执行的功能(可能不能回滚)
      </DialogTitle>
      <DialogContent>
        <Box sx={{display:'flex', flexDirection:'column', width:1, alignItems:'center', gap:2, mb:2}}>
          <Typography>{payload.text}</Typography>
          <Typography color='error' sx={{fontFamily:'monospace'}}>{payload.important}</Typography>
        </Box>
        <Divider variant="middle" />
        { payload.confirmCheck && (
          <FormControlLabel control={
            <Checkbox value={enablePrimary} onClick={() => setEnablePrimary(prev => !prev)}/>
          } label={payload.highlight ?? '确认执行功能'} />
        )}
      </DialogContent>
      <DialogActions>
        { payload.confirmCheck ? (
          <Button variant='outlined' color='error' onClick={()=>onClose('OK')} disabled={!enablePrimary}>{payload.confirmText}</Button>
        ):(
          <Button variant='outlined' color='error' onClick={()=>onClose('OK')}>{payload.confirmText}</Button>
        )}
        <Button onClick={() => onClose(null)}>取消</Button>
      </DialogActions>
    </Dialog>
  )
}