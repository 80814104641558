import { createBrowserRouter } from "react-router";
import Dashboard from "./pages/Dashboard";
import Garminlist from './pages/Garminlist'
import DashboardIcon from '@mui/icons-material/Dashboard';
import type { Navigation } from '@toolpad/core/AppProvider';
import Logout from "./pages/Logout";
import LogoutIcon from '@mui/icons-material/Logout';
import WatchIcon from '@mui/icons-material/Watch';
import SecretCodes from "./pages/Secretcodes";
import Login from "./pages/Login";
import App from "./App";
import EditItem from "./pages/EditItem";
import Gmrecords from "./pages/Gmresults";
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import WxInvoices from "./pages/WxInvoices";
import WxRefunds from "./pages/WxRefunds";
import RefundDetail from "./pages/RefundDetail";
import InvoiceDetail from "./pages/InvoiceDetail";

export const routes = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    element: <App />,
    children: [
      {
        path: '/',
        Component: Dashboard
      },
      {
        path: "/dashboard",
        Component: Dashboard
      },
      {
        path: '/watches/garminlist',
        Component: Garminlist
      },
      {
        path: '/logout',
        Component: Logout,
      },
      {
        path: '/secret_codes/:uid',
        Component: SecretCodes
      },
      {
        path: '/edititem',
        Component: EditItem
      },
      {
        path: '/edititem/:uid',
        Component: EditItem
      },
      {
        path: '/watches/gmrecords',
        Component: Gmrecords
      },
      {
        path: '/invoices/wxinvoice',
        Component: WxInvoices
      },
      {
        path: '/invoices/wxrefund',
        Component: WxRefunds
      },
      {
        path: '/wxrefund/:trade_no',
        Component: RefundDetail
      },
      {
        path: '/invoiceDetail/:trade_no',
        Component: InvoiceDetail
      }
    ]
  }
])

export const approutes = {

}


export const NAVIGATION: Navigation = [
  {
    segment: 'dashboard',
    title: '仪表板',
    icon: <DashboardIcon />,
  },
  {
    kind: 'header',
    title: '主要业务',
  },
  {
    segment: 'watches',
    title: '表盘管理',
    icon: <WatchIcon />,
    children: [
      {
        segment: 'garminlist',
        title: '表盘管理',
      },
      {
        segment: 'gmrecords',
        title: '核销记录'
      }
    ]
  },
  {
    segment: 'invoices',
    title: '订单管理',
    icon: <ReceiptOutlinedIcon />,
    children: [
      {
        segment: 'wxinvoice',
        title: '微信小程序',
      },
      {
        segment: 'wxrefund',
        title: '微信退款订单'
      }
    ]
  },
  {
    kind: 'divider'
  },
  {
    segment: 'logout',
    title: '退出平台',
    icon: <LogoutIcon /> 
  }
];
