import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CodesTable, { CodesTableListType } from "../../Components/CodesTable";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { ChangeEvent, KeyboardEvent,  useCallback,  useEffect,  useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import { useAuth } from "../../Context/AuthContext";
import { useNotifications } from "@toolpad/core/useNotifications";
import { useNavigate } from "react-router";
import { ItemProvider } from "../../Context/ItemContext";
import { Addproduct } from "../../Components/Dialogs";


export default function Grouplist() {
  const [ search, setSearch ] = useState<string>('')
  const [ list, setList ] = useState<CodesTableListType[]>([])
  const [ openAddProd, setOpenAddProd ] = useState<boolean>(false)
  const [ currentItem, setCurrentItem ] = useState<CodesTableListType>({} as CodesTableListType)
  const [startsearch, setStartsearch] = useState<boolean>(true);

  const { token } = useAuth()

  const notification = useNotifications()
  const navigate = useNavigate()

  const getList = useCallback( async () =>  {
    try {
      const options = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify({
          disablePagi: true,
          search: search
        })
      }  
      const res = await fetch('/api/codelist', options)
      if( res.status === 200) {
        const data = await res.json()
        setList(data)
        setStartsearch(false)
        return
      } 
      if ( res.status === 403) {
        navigate('/login')
      } 
      if( res.status === 400) {
        const detail = await res.json()
        notification.show(detail.detail.message, {
          severity: 'error', 
          autoHideDuration: 3000
        })
      }
    } catch (err) {
      console.log(err)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startsearch])
  
  function handleSearch(e:ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value)
  }

  function clearSearch() {
    setSearch('')
    setStartsearch(true)
  }

  function onSearch() {
    setStartsearch(true)
  }

  function handleKeyDown(e:KeyboardEvent<HTMLInputElement>) {
    if (e.key==='Enter') {
      setStartsearch(true)
    }
  }

  function handleAddItem() {
    navigate('/edititem')
  }

  function handleProdOpen(item:CodesTableListType) {
    setCurrentItem(item)
    setOpenAddProd(true)
  }

  function handleEdit(item:CodesTableListType) {
    navigate(`/edititem/${item.uid}`)
  }


  function handleProdClose() {
    setCurrentItem({} as CodesTableListType)
    setOpenAddProd(false)
    setStartsearch(true)
  }

  function handleProdDetail(item:CodesTableListType) {
    navigate("/secret_codes/"+item.uid)
  }

  useEffect( () => {
    getList()
    return () => setStartsearch(false);
  },[getList])

  return (
    <>
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Typography variant="h4" sx={{ pb: 2 }}>
        佳明表盘列表
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          pb: 1,
        }}
      >
        <TextField
          label="搜索"
          size="small"
          fullWidth
          value={search}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: ( 
                <InputAdornment position='end'>
                  { search.length > 0 && 
                      <IconButton onClick={()=> clearSearch()} >
                        <CancelIcon sx={{ fontSize:16 }}/> 
                      </IconButton>
                  }
                </InputAdornment>
              )
            },
          }}
          onChange={(e:ChangeEvent<HTMLInputElement>) => { handleSearch(e)}}
          onKeyDown={(e:KeyboardEvent<HTMLInputElement>) => { handleKeyDown(e)}}
        />
        <Button
          sx={{ minWidth: 150, p: 1, mt: 1, mb: 1 }}
          variant="contained"
          size="small"
          onClick={onSearch}
        >
          搜索
        </Button>
        <Button
          sx={{ minWidth: 150, p: 1, mt: 1, mb: 1, gap: 1 }}
          variant="contained"
          size="small"
          onClick={handleAddItem}
        >
          <AddIcon />
          <>增加</>
        </Button>
      </Box>
      <CodesTable
        tablelist={list}
        onAddProd={handleProdOpen}
        onDetail={handleProdDetail}
        onEdit={handleEdit}
      />
    </Box>
    {/* { openCreate && <AddItem open={ openCreate } onClose={handleAddClose}/> } */}
    { openAddProd && (
      <ItemProvider item_uid={currentItem.uid} item_name={currentItem.name}>
        <Addproduct open={openAddProd} onClose={handleProdClose} />
      </ItemProvider>
    )}
    </>
  );
}