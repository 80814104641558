import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "../../Context/AuthContext";
import { useNotifications } from "@toolpad/core";
import ProdTable, { ProdTypes } from "../../Components/ProdTable";
import { Box, Paper, TextField, Typography } from "@mui/material";

export type ItemType = {
  uid: string;
  name: string;
  pic: string;
  price: number;
  summary: string | null;
  resources: string | null;
  tag: string| null;
  ads_res: string | null;
  usprice: number;
};

export default function SecretCodes() {
  const { uid } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();
  const notification = useNotifications();

  const [unuseList, setUnuseList] = useState<ProdTypes[]>([]);
  const [disclosedList, setDisclosedList] = useState<ProdTypes[]>([]);
  const [verifiedList, setVerifiedList] = useState<ProdTypes[]>([]);
  const [item, setItem] = useState<ItemType>({} as ItemType);
  const [isReady, setIsReady] = useState<boolean>(false);

  async function getlist() {
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          item_uid: uid,
        }),
      };
      const res = await fetch("/api/all_cards", options);
      if (res.status === 200) {
        const groupList = await res.json();
        setUnuseList(groupList.unuse);
        setDisclosedList(groupList.disclosed);
        setVerifiedList(groupList.verified);
      }
      if (res.status === 403) {
        navigate("/login");
      }
      if (res.status === 400) {
        const detail = await res.json();
        notification.show(detail.detail.message, {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getItem() {
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const res = await fetch("/api/item/" + uid, options);
      if (res.status === 200) {
        const data = await res.json();
        setItem(data);
        setIsReady(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function disCloseNow(item_uid: string, prod_uid: string) {
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          item_uid: item_uid,
          prod_uid: prod_uid,
        }),
      };
      const res = await fetch("/api/update_card_state", options);
      if (res.status === 200) {
        notification.show("成功发出", {
          severity: "success",
          autoHideDuration: 3000,
        });
        await getlist();
        return true;
      }
      if (res.status === 403) {
        navigate("/login");
      }
      if (res.status === 400) {
        const detail = await res.json();
        notification.show(detail.detail.message, {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function verifyNow(item_uid: string, prod_uid: string) {
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          item_uid: item_uid,
          prod_uid: prod_uid,
        }),
      };
      const res = await fetch("/api/manual_verify", options);
      if (res.status === 200) {
        notification.show("成功验证", {
          severity: "success",
          autoHideDuration: 3000,
        });
        getlist();
      }
      if (res.status === 403) {
        navigate("/login");
      }
      if (res.status === 400) {
        const detail = await res.json();
        notification.show(detail.detail.message, {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  function onDisclose(item_uid: string, prod_uid: string) {
    console.log(item_uid, prod_uid);
    disCloseNow(item_uid, prod_uid);
  }

  function onVerify(item_uid: string, prod_uid: string) {
    console.log(item_uid, prod_uid);
    verifyNow(item_uid, prod_uid);
  }

  useEffect(() => {
    getlist();
    getItem();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
        gap: 1,
        minHeight: 1,
      }}
    >
      <Typography variant="h4" sx={{ pb: 2 }}>
        {item.name}表盘密卡列表
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
        <Paper
          sx={{
            width: 1 / 2,
            display: "flex",
            flexDirection: "column",
            p: 1,
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: 3 }}>
            <Box sx={{ display: "inline-flex", minWidth: 200 }}>
              {item.pic && <img src={'/upload/media/'+item.pic} width={200} alt="pic" />}
            </Box>
            <Box
              component="form"
              sx={{
                flexGrow: 1,
                minWidth: 1 / 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {!!isReady && (
                <>
                  <TextField
                    variant="standard"
                    label="名称"
                    defaultValue={item.name}
                    slotProps={{ input: { readOnly: true } }}
                  />
                  <TextField
                    variant="standard"
                    label="价格"
                    defaultValue={item.price}
                    slotProps={{ input: { readOnly: true } }}
                  />
                  <TextField
                    variant="standard"
                    label="表盘代码"
                    defaultValue={item.uid}
                    slotProps={{ input: { readOnly: true } }}
                  />
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", width: 1 }}>
            <TextField
              variant="outlined"
              label="描述"
              value={item.summary || ''}
              multiline
              rows={4}
              slotProps={{ input: { readOnly: true } }}
              sx={{ width: 1 }}
            />
          </Box>
          <Box sx={{ display: "flex", width: 1 }}>
            <TextField
              variant="outlined"
              label="轮播资源"
              value={item.ads_res || ''}
              multiline
              rows={4}
              slotProps={{ input: { readOnly: true } }}
              sx={{ width: 1 }}
            />
          </Box>
          <Box sx={{ display: "flex", width: 1 }}>
            <TextField
              variant="outlined"
              label="资源"
              value={item.resources || ''}
              multiline
              rows={4}
              slotProps={{ input: { readOnly: true } }}
              sx={{ width: 1 }}
            />
          </Box>
        </Paper>
        <Paper
          sx={{ display: "flex", flexDirection: "column", p: 2, width: 1 / 2 }}
        >
          <Typography variant="body1" sx={{ ml: 2 }}>
            <pre>{
`验证密卡API:
https://oszero.bolatek.com/outpost/claim
POST 方法:
{
  "card": ${item.uid}
  "code": <卡密>
  "claim_by": <uuid>
}
  
显示产品H5链接:
https://watchface.bolatek.com/items/${item.uid}
`           
            }</pre>
          </Typography>
        </Paper>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 1, flexGrow: 1 }}
      >
        <Paper sx={{ display: "flex", flexDirection: "column", p: 2, gap: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 800, textDecoration: "underline" }}
          >
            未使用的:
          </Typography>
          <ProdTable list={unuseList} onDisclose={onDisclose} />
        </Paper>
        <Paper sx={{ display: "flex", flexDirection: "column", p: 2, gap: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 800, textDecoration: "underline" }}
          >
            已经发出的:
          </Typography>
          <ProdTable list={disclosedList} onVerify={onVerify} />
        </Paper>
        <Paper sx={{ display: "flex", flexDirection: "column", p: 2, gap: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 800, textDecoration: "underline" }}
          >
            已经验证的:
          </Typography>
          <ProdTable list={verifiedList} />
        </Paper>
      </Box>
    </Box>
  );
}
