import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "../../Context/AuthContext";
import { invoiceType } from "../WxInvoices";

export default function RefundDetail() {
  const [invoice, setInvoice] = useState<invoiceType | null>(null);
  const [transResource, setTransResource] = useState<string>("{}");

  const params = useParams();
  const trade_no = params.trade_no;
  const { token } = useAuth();

  const getInvoice = useCallback(async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const res = await fetch("/api/transaction/" + trade_no, options);
      if (res.status === 200) {
        const data = await res.json();
        setInvoice(data);
        setTransResource(data.resource);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, trade_no]);

  useEffect(() => {
    getInvoice();
  }, [getInvoice]);

  const prettyCss: CSSProperties = {
    fontSize: 12,
    color: "GrayText",
    borderWidth: 1,
    backgroundColor: "InfoBackground",
    width: "100ch",
  }

  const prettyprint = (
    <div style={prettyCss}>
      <pre>{JSON.stringify(JSON.parse(transResource), null, 2)}</pre>
    </div>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Typography variant="h4" sx={{ pb: 2 }}>
        详细支付订单: {params.trade_no}
      </Typography>
      <Paper
        sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column", gap: 2 }}
      >
        <FormControl sx={{ width: "50ch" }}>
          <InputLabel htmlFor="trade_num">订单号码</InputLabel>
          <OutlinedInput
            label="订单号码"
            aria-label="trade_num"
            defaultValue={trade_no}
            readOnly
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: "50ch" }}>
          <InputLabel htmlFor="trans_num">交易号码</InputLabel>
          <OutlinedInput
            label="交易号码"
            aria-label="trans_num"
            value={invoice?.transaction_id ? invoice?.transaction_id : " "}
            readOnly
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: "50ch" }}>
          <InputLabel htmlFor="trans_state">付款状态</InputLabel>
          <OutlinedInput
            label="付款状态"
            aria-label="trans_state"
            value={invoice?.inv_status ? invoice.inv_status : " "}
            readOnly
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: "50ch" }}>
          <InputLabel htmlFor="trans_payer">交易Openid</InputLabel>
          <OutlinedInput
            label="交易Openid"
            aria-label="trans_payer"
            value={invoice?.openid ? invoice.openid : " "}
            readOnly
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: "50ch" }}>
          <InputLabel htmlFor="trans_time">交易时间</InputLabel>
          <OutlinedInput
            label="交易时间"
            aria-label="trans_time"
            value={invoice?.createtime ? invoice.createtime : " "}
            readOnly
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: "100ch" }}>
          <InputLabel htmlFor="trans_desc">交易描述</InputLabel>
          <OutlinedInput
            label="交易描述"
            aria-label="trans_desc"
            value={invoice?.description ? invoice.description : " "}
            readOnly
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: "50ch" }}>
          <InputLabel htmlFor="trans_itemuid">表盘编码</InputLabel>
          <OutlinedInput
            label="表盘编码"
            aria-label="trans_itemuid"
            value={invoice?.item_uid ? invoice.item_uid : " "}
            readOnly
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: "50ch" }}>
          <InputLabel htmlFor="trans_prodid">卡密编码</InputLabel>
          <OutlinedInput
            label="卡密编码"
            aria-label="trans_prodid"
            value={invoice?.product_uid ? invoice.product_uid : " "}
            readOnly
            disabled
          />
        </FormControl>
        <Typography
          sx={{ fontSize: 12, textDecorationLine: "underline", ml: 2, mb: 1 }}
        >
          交易报文
        </Typography>
        {prettyprint}
      </Paper>
    </Box>
  );
}
