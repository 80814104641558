import { Box, Button, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import React, { KeyboardEvent, ChangeEvent, useEffect, useState, useCallback } from 'react';
import { useAuth } from '../../Context/AuthContext';
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from '@mui/icons-material/Cancel';
import { useDialogs } from '@toolpad/core';
import { ConfirmProceed } from '../../Components/Dialogs';
import { useNavigate } from 'react-router';

export type ClaimRecordsType = {
  item_uid:string,
  item_name: string,
  item_price: number,
  prod_uid: string,
  claim_by: string,
  claim_date: string,
}

export default function Gmresult() {

  const { token } = useAuth()

  const [ page, setPage ] = useState<number>(0)
  const [ size, setSize ] = useState<number>(10)
  const [ search, setSearch ] = useState<string>('')
  const [ list, setList ] = useState<ClaimRecordsType[]>([])
  const [ listtotal, setListtotal ] = useState<number>(0)

  const dialogs = useDialogs()
  const navigate = useNavigate()

  const getlist = useCallback(async () => {
    try {
      const options = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify({
          page,
          size,
          search
        })
      }
      const res = await fetch('/api/gm_records', options)
      if ( res.status === 200 ) {
        const {list, total } = await res.json()
        setList(list)
        setListtotal(total)
      }
    } catch (err) {
      console.log(err)
    }
  },[page, search, size, token])

  function clearSearch() {
    setSearch('')
  }

  function handleChange(e:ChangeEvent<HTMLInputElement>, setAction:CallableFunction) {
    setAction(e.target.value)
  }

  function searchNow() {
    getlist()
  }

  function handleKeyDown(e:KeyboardEvent<HTMLInputElement>) {
    if (e.key==='Enter') {
      searchNow()
    }
  }

  function handlePageChange(e: unknown, changeto:number) {
    setPage(changeto)
  }

  function handleChangeRowsPerPage(e: ChangeEvent<HTMLInputElement>) {
    setSize(parseInt(e.target.value, 10));
    setPage(0)
  }

  async function handleConfirm(uid:string, uuid:string) {
    await dialogs.open(ConfirmProceed, {
      text: '永久删除核销记录',
      important: uid + ' 已核销:' + uuid,
      confirmText:'永久删除',
      highlight: '确认需要执行删除功能',
      confirmCheck: true
    }).then( (val) => {
      if(val) {
        try {
          const options = {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + token
            },
            body: JSON.stringify({
              uid: uid
            })
          };
          ( async () => {
            const response = await fetch('/api/remove/gm_record', options)
            if (response.status === 200) {
              getlist()
            }
            if (response.status === 403) {
              navigate('/login')
            }
          })()
        } catch (err) {
          console.log(err)
        }
      }
    })
  }

  useEffect(() => {
    getlist()
  },[getlist])

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Typography variant="h4" sx={{ pb: 2 }}>
        核销记录
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2, pb: 1, }} >
        <TextField label="搜索" size="small" fullWidth value={search}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: ( 
                <InputAdornment position='end'>
                  { search.length > 0 && 
                      <IconButton onClick={()=> clearSearch()} >
                        <CancelIcon sx={{ fontSize:16 }}/> 
                      </IconButton>
                  }
                </InputAdornment>
              )
            },
          }}
          onChange={(e:ChangeEvent<HTMLInputElement>) => { handleChange(e, setSearch)}}
          onKeyDown={(e:KeyboardEvent<HTMLInputElement>) => { handleKeyDown(e)}}
        />
        <Button sx={{ minWidth: 150, p: 1, mt: 1, mb: 1 }} variant="contained" size="small" onClick={searchNow}>
          搜索
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{p:1}}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>表盘代码</TableCell>
              <TableCell>表盘名称</TableCell>
              <TableCell align='right'>价格</TableCell>
              <TableCell>密卡</TableCell>
              <TableCell align='right'>验证日期</TableCell>
              <TableCell>验证设备码</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              list.map( (row, idx) => (
                <TableRow key={idx}>
                  <TableCell sx={{fontFamily:'monospace'}}>{row.item_uid}</TableCell>
                  <TableCell>{row.item_name}</TableCell>
                  <TableCell align='right'>{row.item_price}</TableCell>
                  <TableCell sx={{fontFamily:'monospace'}}>{row.prod_uid}</TableCell>
                  <TableCell align='right'>{row.claim_date}</TableCell>
                  <TableCell sx={{fontFamily:'monospace'}}>{row.claim_by}</TableCell>
                  <TableCell>
                  <Box  sx={{display:"flex",flexDirection:'row',gap:1, height:24}}>
                      <Button size="small" sx={{ minHeight:0, minWidth:0}} variant='outlined'
                        color='error'
                        onClick={() => handleConfirm(row.prod_uid, row.claim_by)}
                      >删除</Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter>            
          </TableFooter>
        </Table>
        <TablePagination
          rowsPerPageOptions={[2, 5, 10]}
          labelRowsPerPage='每页显示'
          component="div"
          count={listtotal}
          rowsPerPage={size}
          page={page}
          colSpan={6}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>
    </Box>
  )
}